// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function useConceptApi() {
    // Use toast
    const toast = useToast()
    // Table Handlers
    const refBuildListTable = ref(null)
    const perPage = ref(localStorage.getItem('conceptPerPage') ?? 10)
    const totalBuilds = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 15, 20, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const genreFilter = ref(null)
    const statusFilter = ref(null)
    const pmFilter = ref(null)
    const isLoading = ref(true)
    const genreFetchOptions = ref(null)
    const cameraFetchOptions = ref(null)
    const conceptData = ref(null)
    const conceptPmList = ref(null)
    const teamFilter = ref(null)
    const teamList = ref(null)
    const tableColumns = [
        { key: 'id', sortable: true },
        { key: 'name', sortable: true },
        { key: 'Theme', sortable: true },
        { key: 'concept_genre_id', sortable: true, label: 'Genre' },
        { key: 'user_id', sortable: true, label: 'Creator' },
        { key: 'status', sortable: true },
        { key: 'pm_id', sortable: true, label: 'Pm' },
        { key: 'created_at', sortable: true },
        { key: 'updated_at', sortable: true },
        { key: 'actions' },
    ]
    const tableTeamColumns = [
        { key: 'id', sortable: true },
        { key: 'name', sortable: true },
        { key: 'Theme', sortable: true },
        { key: 'concept_genre_id', sortable: true, label: 'Genre' },
        { key: 'user_id', sortable: true, label: 'Creator' },
        { key: 'team_id', 'label': 'Team', sortable: true },
        { key: 'status', sortable: true },
        { key: 'pm_id', sortable: true, label: 'Pm' },
        { key: 'created_at', sortable: true },
        { key: 'updated_at', sortable: true },
        { key: 'actions' },
    ]
    const statusOptions = [
        { label: 'Approved', value: 'Approved' },
        { label: 'Waiting Approval', value: 'Waiting Approval' },
        { label: 'Development', value: 'Development' },
        { label: 'Redesign', value: 'Redesign' },
        { label: 'Lead Campaign', value: 'Lead Campaign' },
        { label: 'Killed', value: 'Killed' },
    ]

    const dataMeta = computed(() => {
        const localItemsCount = refBuildListTable.value ? refBuildListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalBuilds.value,
        }
    })

    const refetchData = () => {
        localStorage.setItem('conceptPerPage', perPage.value)
        refBuildListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, genreFilter, statusFilter, pmFilter, teamFilter], () => {
        refetchData()
    })

    watch([teamFilter], () => {
        fetchPms()
    })


    // ------------------------------------------------
    // Get Concept List
    // ------------------------------------------------
    const fetchConcepts = (ctx, callback) => {
        let sort = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
        store
            .dispatch('conceptStore/fetchConcepts', {
                sort: sort,
                page: currentPage.value,
                perPage: perPage.value,
                q: searchQuery.value,
                genre: genreFilter.value,
                status: statusFilter.value,
                pm: pmFilter.value,
                team: teamFilter.value,
                pagination: true
            })
            .then(response => {
                const { data, total } = response.data
                callback(data)
                totalBuilds.value = response.data.pagination.total
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    const fetchConcept = (ctx, callback) => {

        store
            .dispatch('conceptStore/fetchConcept', { id: router.currentRoute.params.concept })
            .then(response => {
                const { data } = response.data
                conceptData.value = data
                conceptData.value.concept_camera_id = data.camera.id
                conceptData.value.concept_genre_id = data.genre.id
                conceptData.value.references = data.references ? data.references : []
                isLoading.value = false
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
                router.push('/concept/list');
            })
    }


    const fetchCamera = (ctx, callback) => {
        store
            .dispatch('conceptStore/fetchCamera', {})
            .then(response => {
                const cameraFnc = response.data
                cameraFetchOptions.value = cameraFnc.data.map(item => ({
                    value: item.id,
                    label: item.name,
                    icon: item.icon
                }))
            }).catch(error => {
                console.log(error)
            })
    }
    const fetchGenre = (ctx, callback) => {
        store
            .dispatch('conceptStore/fetchGenre', {})
            .then(response => {
                const mapFnc = response.data
                genreFetchOptions.value = mapFnc.data.map(item => ({ value: item.id, label: item.name }))
            }).catch(error => {
                console.log(error)
            })
    }


    const fetchConceptShortcutDetail = (id, date) => {
        store
            .dispatch('conceptStore/fetchConceptShortCut', {
                id: id,
                date: date,
            })
            .then(response => response.data)
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    const fetchPms = (team_id) => {
        store
            .dispatch('conceptStore/fetchPms', {
                team: teamFilter.value ?? team_id
            })
            .then(response => {
                const { data } = response.data
                conceptPmList.value = data.map(item => ({
                    id: item.id,
                    name: item.name,
                    image: item.image
                }))
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    // ------------------------------------------------
    // Get Team List
    // ------------------------------------------------
    const fetchTeamList = (ctx, callback) => {
        store
            .dispatch('teamStore/fetchTeams')
            .then(response => {
                const { data } = response.data
                teamList.value = data.map(item => ({ value: item.id, label: item.name }))
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    return {
        tableTeamColumns,
        tableColumns,
        perPage,
        currentPage,
        totalBuilds,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refBuildListTable,
        refetchData,
        fetchConcepts,
        statusOptions,
        statusFilter,
        genreFilter,


        fetchGenre,
        genreFetchOptions,
        cameraFetchOptions,
        fetchCamera,
        fetchConcept,
        conceptData,
        isLoading,
        fetchConceptShortcutDetail,
        fetchPms,
        conceptPmList,
        pmFilter,

        teamFilter,
        teamList,
        fetchTeamList,


    }
}
