<template>
    <b-card v-if="conceptData" class="conceptDataOverflow">

        <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="true"
            :paginate-elements-by-height="1400" :filename="conceptData.name" :pdf-quality="2" :manual-pagination="false"
            pdf-format="a4" :pdf-margin="20" pdf-orientation="portrait" pdf-content-width="800px"
            @progress="onProgress($event)" ref="html2Pdf">
            <concept-print-detail slot="pdf-content" :pdfformat="true" :conceptData="conceptData">
            </concept-print-detail>
        </vue-html2pdf>
        <div>
            <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                    <div class="icon mr-1" v-html="conceptData.camera.icon">
                    </div>
                    <div class="info">
                        <h3>{{conceptData.name}}
                        </h3>
                        <h6>
                            <span v-if="$can('read', 'conceptTeamAccess') && conceptData.team">
                                {{ conceptData.team.name }} -
                            </span>
                            {{conceptData.status}}

                        </h6>
                    </div>
                </div>
                <div class="ml-auto">
                    <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-secondary" @click="generatePDF()">
                        <feather-icon icon="DownloadIcon" class="mr-50" />
                        <span class="align-middle">Download</span>
                    </b-button>
                </div>
            </div>
            <concept-print-detail v-if="conceptData" :pdfformat="false" :conceptData="conceptData">
            </concept-print-detail>
        </div>
    </b-card>
    <b-card v-else-if="isLoading" class="mt-2">
      <div class="text-center my-2">
        <b-spinner class="d-block mx-auto mb-50"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </b-card>
</template>

<script>
import { BCard, BCardText,BBadge,BImg,BAlert,BButton, BRow, BCol,BSpinner  } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import VueHtml2pdf from 'vue-html2pdf'
import ConceptPrintDetail from '@/components/ConceptPrintDetail.vue'
import useConceptApi from '@/composables/useConceptApi'
export default {
    components: {
        BSpinner,
        BCard,
        BCardText,
        BBadge,
        BImg,
        BAlert,
        BButton,
        BRow,
        BCol,
        VueHtml2pdf,
        ConceptPrintDetail
    },
    directives: {
        Ripple,
    },
    mounted(){
        this.fetchConcept(router.currentRoute.params.concept)
    },
    setup() {
        const {
            conceptData,
            fetchConcept,
            isLoading,
            } = useConceptApi()

        return{
            conceptData,
            fetchConcept,
            isLoading
        }
    },
    methods: {
        onProgress(event) {
            console.log(`Processed: ${event} / 100`);
        },
        hasGenerated() {
            console.log("PDF generated successfully!");
        },
        generatePDF() {
            this.$refs.html2Pdf.generatePdf();
        },
    }
}
</script>

<style>

</style>
