<template>


    <div class="px-1" id="detailConceptPage">

        <div class="d-flex align-items-center" v-if="pdfformat">
            <div class="d-flex align-items-center">
                <div class="icon mr-1" v-html="conceptData.camera.icon">
                </div>
                <div class="info">
                    <h3>{{ conceptData.name }}
                    </h3>
                </div>
            </div>

        </div>
        <hr>


        <b-row class="mt-3">
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <h5>Theme</h5>
                {{ conceptData.theme }}
            </b-col>

            <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <h5>Genre</h5>
                {{ conceptData.genre.name }}
            </b-col>

            <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <h5>Camera</h5>
                {{ conceptData.camera.name }}
            </b-col>
        </b-row>


        <div class="br-desc mt-3" v-if="conceptData.brief">
            <h5>Brief Description:</h5>
            <p class="card-text" v-html="conceptData.brief"/>
        </div>

        <div class="br-desc mt-3" v-if="conceptData.references.length > 0">
            <h5>Reference Links:</h5>
            <div v-for="refItem in conceptData.references" :key="refItem.id"><a class="w-100 d-block mb-50"
                                                                                :href="refItem.link" target="_blank"
                                                                                rel="noreferrer noopener">{{ refItem.link }}</a>
            </div>
        </div>

        <div class="level-info mt-3" v-if="conceptData.level_system_description">
            <h5>Level System:</h5>
            <p class="card-text" v-html="conceptData.level_system_description"/>
        </div>
        <div class="unl-info mt-3" v-if="conceptData.unlockable_content_description">
            <h5>Unlockable Content:</h5>
            <p class="card-text" v-html="conceptData.unlockable_content_description"/>
        </div>

        <div class="score-info mt-3" v-if="conceptData.score_system_description">
            <h5>Score System:</h5>
            <p class="card-text" v-html="conceptData.score_system_description"/>
        </div>

        <div class="upg-info mt-3" v-if="conceptData.upgrade_system_description">
            <h5>Upgrade System:</h5>
            <p class="card-text" v-html="conceptData.upgrade_system_description"/>
        </div>


        <div class="cntrl-info mt-3" v-if="conceptData.controls">
            <h5>Controls:</h5>
            <p class="card-text" v-html="conceptData.controls"/>
        </div>

        <div class="game-info mt-3" v-if="conceptData.loop">
            <h5>Game Loop:</h5>
            <p class="card-text" v-html="conceptData.loop"/>
        </div>

        <div class="art-info mt-3" v-if="conceptData.artstyle">
            <h5>Art Style:</h5>
            <p class="card-text" v-html="conceptData.artstyle"/>
        </div>

        <div class="meta-info mt-3" v-if="conceptData.meta">
            <h5>Meta Game:</h5>
            <p class="card-text" v-html="conceptData.meta"/>
        </div>

        <div class="ret-info mt-3" v-if="conceptData.retention">
            <h5>Retention Features:</h5>
            <p class="card-text" v-html="conceptData.retention"/>
        </div>

        <div class="add-info mt-3" v-if="conceptData.additional">
            <h5>Additional Information:</h5>
            <p class="card-text" v-html="conceptData.additional"/>
        </div>
    </div>
</template>

<script>
import {BCard, BCardText, BBadge, BImg, BAlert, BButton, BRow, BCol,} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    name: "ConceptPrintDetail",
    components: {
        BCard,
        BCardText, BRow, BCol, BButton
    },
    directives: {
        Ripple,
    },
    props: {
        conceptData: {},
        pdfformat: ''
    }

}
</script>

<style>
#detailConceptPage img {
    max-width: 100%;
}

</style>
